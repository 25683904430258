import React, { useState } from 'react';
import './about.css'; // Make sure to style the slider in this file
import BackToTop from '../../BackToTop';

function About() {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <section className="about-hero">
        <div className="hero-content">
          <h1>Welcome to Poovanam</h1>  
          <p>Delivering high-quality, fresh milk to your doorstep.</p>
        </div>
      </section>

      {/* About Section with Slider */}
      <section className="about-content">
        <div className="content-container">
          <div className="about-text">
            <h2>About Us</h2>
            <p>
              Poovanam Org Agro Products is committed to delivering natural and farm-fresh cow milk to your home every day.
              Now you can enjoy natural, fresh, and creamy milk sourced from healthy cows that graze on natural feed only.
              Absolutely no preservatives, hormones, or antibiotics are added. The milk is packed in environment-friendly glass bottles.
            </p>
          </div>

           

        </div>
      </section>

      <BackToTop />
    </div>
  );
}

export default About;
