// reducers/cartReducer.js
const initialState = {
    cartItems: [],  // Initialize as an empty array
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_ITEM":
        // Logic for adding item
        break;
      case "DEL_ITEM":
        // Logic for deleting item
        break;
      case "INCREMENT_QUANTITY":
        // Logic for incrementing quantity
        break;
      case "DECREMENT_QUANTITY":
        // Logic for decrementing quantity
        break;
      default:
        return state;
    }
  };
  
  export default cartReducer;
  