import React, { useState, useEffect } from "react";
import "./lab.css";

// Importing folder image and file images
import folderImage from "../../assets/folder.jpg";
import fileImage from "../../assets/files.webp";

const LabReport = () => {
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Simulating API or static folder fetch
        const fetchFolders = async () => {
            const folderData = ["Sample", "Assurance", "Certificates"];
            setFolders(folderData);
        };

        fetchFolders();
    }, []);

    const openFolder = (folderName) => {
        const imageList = [
            fileImage,
            fileImage,
            fileImage,
            fileImage
        ];
        setSelectedFolder(folderName);
        setImages(imageList);
    };

    return (
        <div className="lab-report">
            {/* Heading with animated lab effect and icons */}
            <h1 className="report-heading">
                <span className="lab-icon">🧪</span>
                POOVANAM LAB REPORTS
                <span className="lab-icon">⚗️</span>
            </h1>
            
            {!selectedFolder ? (
                <div className="folder-view">
                    {folders.map((folder) => (
                        <div key={folder} className="folder-card" onClick={() => openFolder(folder)}>
                            <div className="folder-image">
                                <img src={folderImage} alt="Folder" className="folder-img" />
                            </div>
                            <div className="folder-name">{folder}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="image-view">
                    <button onClick={() => setSelectedFolder(null)} className="back-button">
                        Back to Folders
                    </button>
                    <div className="images">
                        {images.map((image, index) => (
                            <div key={index} className="image-card">
                                <img src={image} alt={`Report ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabReport;
