import React, { useState, useEffect, useRef } from 'react';
import './shop.css';
import { useNavigate } from 'react-router-dom';

import heroImage from '../../assets/no10.jpg';
import popularMilkImage1 from '../../assets/product_milkbt.png';
import popularMilkImage5 from '../../assets/product_milk.png';
import popularMilkImage2 from '../../assets/ghee2.webp';
import popularMilkImage6 from '../../assets/ghee2.webp';

const Shop = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [cartItems, setCartItems] = useState(() => {
        const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
        return savedCartItems || [];
    });

    const productsSectionRef = useRef(null); // Ref for products section
    const viewCartRef = useRef(null); // Ref for "View Cart" section
    const navigate = useNavigate();

    const products = [
        { id: 1, name: "Milk", price: 70.00, unit: "/1000ml", image: popularMilkImage1, description: "Pure and fresh cow milk directly from the farmer." },
        { id: 2, name: "Milk", price: 38.00, unit: "/500ml", image: popularMilkImage5, description: "Pure and fresh cow milk directly from the farmer." },
        { id: 3, name: "Ghee", price: 999.00, unit: "/1000ml", image: popularMilkImage2, description: "Made from 100% pure cow's milk." },
        { id: 4, name: "Ghee", price: 499.00, unit: "/500ml", image: popularMilkImage6, description: "Made from 100% pure cow's milk." },
    ];

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchQuery)
    );

    // Scroll to the products section when the search query changes
    useEffect(() => {
        if (searchQuery.trim() && productsSectionRef.current) {
            productsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [searchQuery]);

    const addToCart = (product) => {
        setCartItems(prevItems => {
            const itemExists = prevItems.find(item => item.id === product.id);
            if (itemExists) {
                return prevItems.map(item =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + 1, totalPrice: (item.quantity + 1) * item.price }
                        : item
                );
            } else {
                return [...prevItems, { ...product, quantity: 1, totalPrice: product.price }];
            }
        });

        if (viewCartRef.current) {
            viewCartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const viewCart = () => {
        navigate('/cart');
    };

    const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);

    return (
        <div className="shop-page">
            <header className="shop-header">
                <input
                    type="text"
                    placeholder="Search products..."
                    className="search-bar"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </header>

            <section className="hero-section">
                <img src={heroImage} alt="Hero" className="hero-image" />
            </section>

            <section className="product-section" ref={productsSectionRef}>
                <div className="product-heading">
                    <h2 className="heading">Our Products</h2>
                </div>
                <div className="product-cards">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map(product => (
                            <div key={product.id} className="product-card">
                                <img src={product.image} alt={product.name} className="product-image1" />
                                <h3 className="product-name">{product.name}</h3>
                                <p className="product-price">
                                    {product.description === "Coming Soon"
                                        ? "Coming Soon"
                                        : `₹${product.price}${product.unit}`}
                                </p>
                                <p className="product-description">{product.description}</p>
                                {product.description !== "Coming Soon" && (
                                    <button onClick={() => addToCart(product)} className="buy-button">Add to Cart</button>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No products found for your search.</p>
                    )}
                </div>
            </section>

            <div className="view-cart-container" ref={viewCartRef}>
                <button className="view-cart-button" onClick={viewCart}>
                    View Cart {cartCount > 0 && <span className="cart-count">({cartCount})</span>}
                </button>
            </div>
        </div>
    );
};

export default Shop;
