import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './cart.css';

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(savedCartItems);
    }, []);

    const updateCart = (items) => {
        setCartItems(items);
        localStorage.setItem('cartItems', JSON.stringify(items));
    };

    const increaseQuantity = (id) => {
        const updatedItems = cartItems.map(item =>
            item.id === id ? { ...item, quantity: item.quantity + 1 } : item
        );
        updateCart(updatedItems);
    };

    const decreaseQuantity = (id) => {
        const updatedItems = cartItems.map(item =>
            item.id === id && item.quantity > 1
                ? { ...item, quantity: item.quantity - 1 }
                : item
        ).filter(item => item.quantity > 0);
        updateCart(updatedItems);
    };

    const removeItem = (id) => {
        const updatedItems = cartItems.filter(item => item.id !== id);
        updateCart(updatedItems);
    };

    const calculateSubtotal = (item) => parseFloat(item.price) * item.quantity;
    const subtotal = cartItems.reduce((acc, item) => acc + calculateSubtotal(item), 0);
    const shipping = 0.0;
    const total = subtotal + shipping;

    // Navigate to Shop page to add more items
    const handleAddMoreItems = () => {
        navigate('/shop');
    };

    const handleCheckout = () => {
        navigate('/checkout', { state: { cartItems } });
    };

    return (
        <div className="cart-page">
            <h2>Your Cart</h2>
            {cartItems.length > 0 ? (
                <>
                    <table className="cart-table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Subtotal</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.map(item => (
                                <tr key={item.id}>
                                    <td className="product-info">
                                        <img src={item.image} alt={item.name} className="cart-item-image" />
                                        <span>{item.name}</span>
                                    </td>
                                    <td>₹{parseFloat(item.price).toFixed(2)}</td>
                                    <td className="quantity-controls">
                                        <button onClick={() => decreaseQuantity(item.id)}>-</button>
                                        <span>{item.quantity}</span>
                                        <button onClick={() => increaseQuantity(item.id)}>+</button>
                                    </td>
                                    <td>₹{calculateSubtotal(item).toFixed(2)}</td>
                                    <td>
                                        <button onClick={() => removeItem(item.id)} className="remove-button">Remove</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="cart-actions">
                        <button className="add-more-items-button" onClick={handleAddMoreItems}>Add More Items</button>
                    </div>

                    <div className="cart-summary">
                        <p>Subtotal: <span>₹{subtotal.toFixed(2)}</span></p>
                        <p>Shipping: <span>₹{shipping.toFixed(2)}</span></p>
                        <p className="total">Total: <span>₹{total.toFixed(2)}</span></p>
                        <button className="checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
                    </div>
                </>
            ) : (
                <div className="cart-empty">
                    <p>Your cart is empty.</p>
                    <button onClick={() => navigate('/shop')} className="back-to-shop-button">Back to Shop</button>
                </div>
            )}
        </div>
    );
};

export default Cart;
