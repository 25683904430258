import React, { useRef, useState, useEffect } from "react";
import "./contact.css";

import phoneIcon from "../../assets/logo_transparent.png";
import facebookIcon from "../../assets/facebook.png";
import twitterIcon from "../../assets/X.png";
import linkedinIcon from "../../assets/linkedIn.png";
import ringSound from "../../assets/cow.mp3";

function Contact() {
  const formRef = useRef();
  const ringRef = useRef(null);
  const [canPlaySound, setCanPlaySound] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "+91",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    message: "",
  });

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  // Enable sound on user interaction
  useEffect(() => {
    const enableSound = () => setCanPlaySound(true);
    document.addEventListener("click", enableSound, { once: true });
    document.addEventListener("touchstart", enableSound, { once: true });
    return () => {
      document.removeEventListener("click", enableSound);
      document.removeEventListener("touchstart", enableSound);
    };
  }, []);

  const playSound = () => {
    if (canPlaySound && ringRef.current) {
      ringRef.current.play();
    }
  };

  const pauseSound = () => {
    if (ringRef.current) {
      ringRef.current.pause();
      ringRef.current.currentTime = 0;
    }
  };

  // Validate fields and update button state
  const validateFields = () => {
    const { name, number, email, address, city, state, pincode } = formData;
    const newErrors = {};

    if (!name) newErrors.name = "Name is required.";
    if (!number.startsWith("+91") || number.length !== 13 || !/^\+91\d{10}$/.test(number))
      newErrors.number = "Phone number must start with +91 and be 10 digits.";
    if (
      !email ||
      !/^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|outlook\.com|hotmail\.com)$/.test(email)
    )
      newErrors.email = "Email must be valid and end with @gmail.com, @yahoo.com, etc.";
    if (!address) newErrors.address = "Address is required.";
    if (!city) newErrors.city = "City is required.";
    if (!state) newErrors.state = "State is required.";
    if (!pincode || !/^\d{6}$/.test(pincode))
      newErrors.pincode = "Pincode must be exactly 6 digits.";

    setErrors(newErrors);
    setButtonDisabled(Object.keys(newErrors).length > 0);
  };

  useEffect(() => {
    validateFields();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Mark field as touched on change
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    // Mark field as touched on blur
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const { name, number, email, address, city, state, pincode, message } =
      formData;
    const mailtoLink = `mailto:adhithyan191102@gmail.com?subject=New%20Contact%20Form%20Submission&body=${encodeURIComponent(
      `Name: ${name}\nPhone Number: ${number}\nEmail: ${email}\nAddress: ${address}\nCity: ${city}\nState: ${state}\nPincode: ${pincode}\nMessage: ${
        message || "N/A"
      }`
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>We'd love to hear from you! Fill out the form below to get in touch.</p>

      <div className="contact-main">
        <section className="contact-info">
          <img
            src={phoneIcon}
            alt="Phone Icon"
            className="contact-phone"
            onMouseEnter={playSound}
            onMouseLeave={pauseSound}
          />
          <audio ref={ringRef} src={ringSound} preload="auto" />

          <p className="contact-number">+91 8012801239</p>
          <p className="contact-email">enquiry@poovanamorgagro.com</p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/poovanam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a
              href="https://www.twitter.com/poovanam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/in/poovanam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </div>
        </section>

        <section className="contact-form">
          <form ref={formRef} onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.name && errors.name && <div className="error">{errors.name}</div>}

            <input
              type="text"
              name="number"
              placeholder="Your Phone Number (+91 required)"
              value={formData.number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.number && errors.number && <div className="error">{errors.number}</div>}

            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && <div className="error">{errors.email}</div>}

            <input
              type="text"
              name="address"
              placeholder="Your Address"
              value={formData.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.address && errors.address && <div className="error">{errors.address}</div>}

            <input
              type="text"
              name="city"
              placeholder="Your City"
              value={formData.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.city && errors.city && <div className="error">{errors.city}</div>}

            <input
              type="text"
              name="state"
              placeholder="Your State"
              value={formData.state}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.state && errors.state && <div className="error">{errors.state}</div>}

            <input
              type="text"
              name="pincode"
              placeholder="Your Pincode"
              value={formData.pincode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.pincode && errors.pincode && (
              <div className="error">{errors.pincode}</div>
            )}

            <textarea
              name="message"
              placeholder="Your Message (Optional)"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>

            <button
              type="submit"
              className="submit-button"
              disabled={isButtonDisabled}
            >
              Send Message
            </button>
          </form>
        </section>
      </div>

      <div className="divider"></div>

      <section className="map-section">
      <iframe
          title="Poovanam Org Agro Products Location"
          src="https://www.google.com/maps/embed/v1/place?q=Poovanam%20Org%20Agro%20Products%20No.%202,%20Grace%20Street%20Sri%20Lakshmi%20Nagar%20Maduravoyal,%20Chennai%20600%20095%20Tamilnadu,%20India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          allowfullscreen=""
          id="google-maps-canvas"
        ></iframe>
      </section>
    </div>
  );
}

export default Contact;
