import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import BackToTop from './BackToTop';
import Home from './Pages/Home/Home';
import About from './Pages/About/about';
import Product from './Pages/Products/product';
import Shop from './Pages/Shop/shop';
import Lab from './Pages/Lab_Report/lab';
import Cart from './Pages/Cart/cart';
import Checkout from './Pages/Checkout/checkout';
import Contact from './Pages/Contact/contact';
import ScrollToTop from './ScrollToTop'; 
import preloaderVideo from './assets/christmas greetings.mov.mp4';
import './App.css';

const ErrorBoundary = ({ children }) => (
  <React.Suspense fallback={<div>Loading...</div>}>
    {children}
  </React.Suspense>
);

function App() {
  const [loading, setLoading] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    const preloaderTimer = setTimeout(() => {
      setShowCloseButton(true); // Show the close button after 5 seconds
    }, 5000);

    return () => clearTimeout(preloaderTimer); // Cleanup the timer
  }, []);

  const handleClose = () => {
    setLoading(false); // Close the preloader
  };

  return (
    <Router>
      <ScrollToTop /> {/* Add this to ensure scrolling to the top */}
      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <video 
              src={preloaderVideo} 
              autoPlay 
              loop 
              muted 
              className="preloader-video"
            ></video>
          </div>
      
          {showCloseButton && (
            <button className="close-button" onClick={handleClose}>
              X
            </button>
          )}
        </div>
      ) : (
        <>
          <Navbar />
          <BackToTop />
          <Routes>
            <Route path="/" element={<ErrorBoundary><Home /></ErrorBoundary>} />
            <Route path="/about" element={<ErrorBoundary><About /></ErrorBoundary>} />
            <Route path="/product" element={<ErrorBoundary><Product /></ErrorBoundary>} />
            <Route path="/shop" element={<ErrorBoundary><Shop /></ErrorBoundary>} />
            <Route path="/lab" element={<ErrorBoundary><Lab /></ErrorBoundary>} />
            <Route path="/cart" element={<ErrorBoundary><Cart /></ErrorBoundary>} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/contact" element={<ErrorBoundary><Contact /></ErrorBoundary>} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
