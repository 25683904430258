import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 
import logo from './assets/logo_transparent.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <nav className="navbar">
            <div className="logos">
                <Link to="/" onClick={scrollToTop}>
                    <img src={logo} alt="Poovaanam Logo" className="logos-image" />
                </Link>
            </div>

            {/* Desktop Menu */}
            <div className="menu-wrapper">
                <ul className="navbar-menu">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/product">Products</Link></li>
                    <li><Link to="/shop">Shop</Link></li>
                    <li><Link to="/lab">Lab_Report</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                <Link to="/shop">
                    <button className="btn order-now-btn">Order Now</button>
                </Link>
            </div>

            {/* Hamburger Icon for Mobile View */}
            <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="mobile-menu">
                    <button className="close-btn" onClick={toggleMenu}>X</button>
                    <ul>
                        <li><Link to="/" onClick={() => { toggleMenu(); scrollToTop(); }}>Home</Link></li>
                        <li><Link to="/about" onClick={() => { toggleMenu(); scrollToTop(); }}>About</Link></li>
                        <li><Link to="/product" onClick={() => { toggleMenu(); scrollToTop(); }}>Products</Link></li>
                        <li><Link to="/shop" onClick={() => { toggleMenu(); scrollToTop(); }}>Shop</Link></li>
                        <li><Link to="/lab" onClick={() => { toggleMenu(); scrollToTop(); }}>Lab_Report</Link></li>
                        <li><Link to="/contact" onClick={() => { toggleMenu(); scrollToTop(); }}>Contact</Link></li>
                    </ul>
                    <Link to="/shop">
                        <button className="order-now-btn">Order Now</button>
                    </Link>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
