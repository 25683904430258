import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './product.css';
import popularMilkImage1 from '../../assets/product_milkbt.png';
import popularMilkImage5 from '../../assets/product_milk.png';
import popularMilkImage2 from '../../assets/ghee2.webp';
import popularMilkImage3 from '../../assets/coming-soon.png';
import popularMilkImage4 from '../../assets/coming-soon.png';
import popularMilkImage6 from '../../assets/ghee2.webp';

const Product = () => {
  return (
    <div className="main-product-container">
      {/* Popular Products Section */}
      <section className="product-section">
        <h2 className="product-heading">Explore our Premium Products</h2>
        <div className="product-cards">
          <Link to="/shop" className="product-card">
            <img src={popularMilkImage1} alt="Fresh Milk" className="product-image1" />
            <h3 className="product-name">Milk</h3>
            <p className="product-price">₹70.00/1000ml</p>
            <p className="product-description">Pure and fresh cow milk directly from the farmer.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage5} alt="Fresh Milk" className="product-image1" />
            <h3 className="product-name">Milk</h3>
            <p className="product-price">₹38.00/500ml</p>
            <p className="product-description">Pure and fresh cow milk directly from the farmer.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage2} alt="Ghee" className="product-image1" />
            <h3 className="product-name">Ghee</h3>
            <p className="product-price">₹999.00/1000ml</p>
            <p className="product-description">Made from 100% pure cow's milk.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage6} alt="Ghee" className="product-image1" />
            <h3 className="product-name">Ghee</h3>
            <p className="product-price">₹499.00/500ml</p>
            <p className="product-description">Made from 100% pure cow's milk.</p>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage3} alt="Paneer" className="product-image1" />
            <h3 className="product-name">Paneer</h3>
            <h3>Coming Soon.</h3>
          </Link>

          <Link to="/shop" className="product-card">
            <img src={popularMilkImage4} alt="Chekku Oil" className="product-image1" />
            <h3 className="product-name">Chekku Oil</h3>
            <h3>Coming Soon.</h3>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Product;
