import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from './assets/logo_main.png';

const Footer = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo Section */}
        <div className="footer-section logo2">
          <Link to="/" onClick={scrollToTop}>
            <img src={logo} alt="Poovanam Logo" className="footer-logos" />
          </Link>
        </div>

        {/* Address Section */}
        <div className="footer-section address">
          <h4>Address</h4>
          <p><FaMapMarkerAlt /> No. 2, Grace Street Sri Lakshmi Nagar Maduravoyal,<br /> Chennai 600 095 Tamilnadu, India</p>
        </div>

        {/* Follow Us Section with Link Buttons */}
        <div className="footer-section social">
          <h4>Follow us:</h4>
          <div className="social-icons">
            <a href="https://www.facebook.com/poovanam" target="_blank" rel="noopener noreferrer" className="social-link"><FaFacebookF /></a>
            <a href="mailto:enquiry@poovanamorgagro.com" target="_blank" rel="noopener noreferrer" className="social-link"><FaEnvelope /></a>
            <a href="https://www.linkedin.com/in/poovanam" target="_blank" rel="noopener noreferrer" className="social-link"><FaLinkedin /></a>
            <a href="https://www.twitter.com/poovanam" target="_blank" rel="noopener noreferrer" className="social-link"><FaTwitter /></a>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact">
          <h4>Telephone & Email</h4>
          <p><FaPhoneAlt /> <a href="tel:8012801239" className="contact-link">+91 8012801239</a></p>
          <p><FaEnvelope /> <a href="mailto:enquiry@poovanamorgagro.com" className="contact-link">enquiry@poovanamorgagro.com</a></p>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>© Copyright 2024 Poovanam | All Rights Reserved</p>
        <p>Created by <a href="https://litvik.in">Litvik.in</a></p>
      </div>
    </footer>
  );
};

export default Footer;
