import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './checkout.css';
import PhonePeImage from '../../assets/phonePe.png';

const Checkout = () => {
    const [cartItems, setCartItems] = useState([]);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [comments, setComments] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // Retrieve cartItems from location.state (passed from Cart page)
    useEffect(() => {
        if (location.state && location.state.cartItems) {
            setCartItems(location.state.cartItems);
        }
    }, [location]);

    // Calculate subtotal for each item
    const calculateSubtotal = (item) => parseFloat(item.price) * item.quantity;

    const subtotal = cartItems.reduce((acc, item) => acc + calculateSubtotal(item), 0);
    const shipping = 0.0;
    const total = subtotal + shipping;

    // Handle quantity increment and decrement
    const handleQuantityChange = (index, change) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity = Math.max(1, updatedCartItems[index].quantity + change); // Prevent quantity from going below 1
        setCartItems(updatedCartItems);
    };

    // Validate form (comments is now optional)
    const isFormValid = () => {
        return (
            fullName &&
            email &&
            phone &&
            address &&
            state &&
            city &&
            pincode
        );
    };

    // Handle phone input (numbers only)
  // Handle phone number input (must start with +91 and allow exactly 10 digits after +91)
const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Ensure the input starts with +91 and allows only 10 additional digits (13 characters in total)
    if (value.startsWith("+91")) {
        if (/^\+91\d{0,10}$/.test(value)) {
            setPhone(value);
        }
    } else {
        // Automatically add +91 if it's not already there
        setPhone("+91");
    }
};

// Handle pincode input (numbers only and exactly 6 digits)
const handlePincodeChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
        setPincode(value);
    }
};


    // Handle form submission
    const handleFormSubmit = () => {
        if (isFormValid()) {
            // Construct the mailto link
            const subject = `Order Details from ${fullName}`;
            const body = `
                Full Name: ${fullName}
                Email: ${email}
                Phone: ${phone}
                Address: ${address}
                State: ${state}
                City: ${city}
                Pincode: ${pincode}
                Comments: ${comments || 'No comments provided'}
                
                Order Summary:
                ${cartItems
                    .map(
                        (item) =>
                            `${item.name} - ₹${item.price} x ${item.quantity} = ₹${(
                                parseFloat(item.price) * item.quantity
                            ).toFixed(2)}`
                    )
                    .join('\n')}
                
                Subtotal: ₹${subtotal.toFixed(2)}
                Shipping: ₹${shipping.toFixed(2)}
                Total: ₹${total.toFixed(2)}
            `;

            const mailtoLink = `mailto:adhithyan191102@gmail.com?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(body)}`;

            // Open the user's email client
            window.location.href = mailtoLink;

            // Simulate successful submission
            setTimeout(() => {
                setIsFormSubmitted(true); // Show payment option after mail is triggered
            }, 2000);
        } else {
            alert('Please fill out all mandatory fields.');
        }
    };

    // Navigate back to Cart
    const handleBackToCart = () => {
        navigate('/cart', { state: { cartItems } }); // Pass current cartItems to the cart page
    };

    return (
        <div className="checkout-page">
            <div className="checkout-container">
                {/* Left Side: Contact Information */}
                <div className="checkout-form">
                    <h2>Contact Information</h2>
                    <label>
                        Full Name <span className="mandatory">*</span>
                    </label>
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                    />

                    <label>
                        Email Address <span className="mandatory">*</span>
                    </label>
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <label>
                        Phone Number <span className="mandatory">*</span>
                    </label>
                    <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={handlePhoneChange}
                        required
                    />

                    <label>
                        Address <span className="mandatory">*</span>
                    </label>
                    <textarea
                        placeholder="Shipping Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />

                    <label>
                        State <span className="mandatory">*</span>
                    </label>
                    <input
                        type="text"
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        required
                    />

                    <label>
                        City <span className="mandatory">*</span>
                    </label>
                    <input
                        type="text"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />

                    <label>
                        Pincode <span className="mandatory">*</span>
                    </label>
                    <input
                        type="text"
                        placeholder="Pincode"
                        value={pincode}
                        onChange={handlePincodeChange}
                        required
                    />

                    <label>
                        Comments (Optional)
                    </label>
                    <textarea
                        placeholder="Comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />

                    {/* Submit Button */}
                    <button className="submit-button" onClick={handleFormSubmit}>
                        Submit
                    </button>

                    {/* Payment Option */}
                    {isFormSubmitted && (
                        <div className="payment-options">
                        <h2>Select Payment Method</h2>
                        <div className="payment-option">
                            <img
                                src={PhonePeImage} // Use the imported image
                                alt="PhonePe"
                                className="payment-image"
                            />
                        </div>
                    </div>
                    
                        
                    )}
                </div>

                {/* Right Side: Order Summary */}
                <div className="order-summary">
                    <h3>Order Summary</h3>
                    {cartItems.length > 0 ? (
                        <ul>
                            {cartItems.map((item, index) => (
                                <li key={index}>
                                    <span>{item.name}</span> - ₹{parseFloat(item.price).toFixed(2)} x{' '}
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleQuantityChange(index, -1)}
                                    >
                                        -
                                    </button>
                                    {item.quantity}
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleQuantityChange(index, 1)}
                                    >
                                        +
                                    </button>{' '}
                                    = ₹{(parseFloat(item.price) * item.quantity).toFixed(2)}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Your cart is empty.</p>
                    )}
                    <h4>Subtotal: ₹{subtotal.toFixed(2)}</h4>
                    <h4>Shipping: ₹{shipping.toFixed(2)}</h4>
                    <h4>Total: ₹{total.toFixed(2)}</h4>
                    <button className="back-to-cart-button" onClick={handleBackToCart}>
                        Back to Cart
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
